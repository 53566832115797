import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '../../../@core/services';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'ubi-redirect-profile',
  template: '',
  styles: [''],
})
export class RedirectProfileComponent implements OnInit {

  constructor(protected logger: NGXLogger, protected settings: SettingsService, private router: Router) { }

  ngOnInit() {

    const url = this.settings.keycloakConfig.url;
    const realm = this.settings.keycloakConfig.realm;
    const client = this.settings.keycloakConfig.clientId;
    const origin = window.location.origin;

    this.router.navigate(['/']).then(() => {
      window.open(`${url}/realms/${realm}/account?referrer=${client}&referrer_uri=${origin}`, '_self');
    });
  }

}
