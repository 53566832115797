import { FormDefinitionTag } from './form-definition-tag';

export class FormDefinition {
    id: string;
    key: string;
    designation: string;
    definition: any;
    version: string;
    tags: FormDefinitionTag[];
}
