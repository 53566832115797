import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ubi-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {

  public breadcrumbs: Breadcrumb[];

  constructor() { }

  ngOnInit() {}

}

export interface Breadcrumb {
  label: string;
  url: string;
}
