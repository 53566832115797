import { Component, OnInit, Output, EventEmitter, OnChanges, forwardRef, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const START_ALPHABET = 65;
const END_ALPHABET = 90;

@Component({
  selector: 'ubi-alphabet-search',
  templateUrl: './alphabet-search.component.html',
  styleUrls: ['./alphabet-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AlphabetSearchComponent),
      multi: true,
    },
  ],
})

export class AlphabetSearchComponent implements OnInit, ControlValueAccessor, OnChanges {
  alphabetLetters = [];
  value: any;

  // Event emmited when select a letter
  @Output()
  onLetterSelected = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    for (let i = START_ALPHABET; i <= END_ALPHABET; i++) {
      this.alphabetLetters.push({ id: i, letter: String.fromCharCode(i) });
    }
  }

  /**
   * On click on letter event emmited
   * @param letter
   */
  search(letter: any) {
    this.onLetterSelected.emit(letter.id);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  pushChanges(value: any) {
    this.onChange(value);
  }

  registerOnChange(fn: (_: any) => {}): void { this.onChange = fn; }
  registerOnTouched(fn: () => {}): void { this.onTouched = fn; }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item && changes.item.currentValue && changes.item.previousValue !== changes.item.currentValue) {
      this.writeValue(changes.item.currentValue);
    }
  }

  onChange = (_: any) => { };
  onTouched = () => { };
}
