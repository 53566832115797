import { Injectable } from '@angular/core';

/**
 * Utilities Service
 */
@Injectable()
export class UtilsService {

  constructor() { }

  /**
   * Is null, undefined or empty space
   * @param value Value
   */
  isNullOrEmptySpace(value: string) {
    return value == null || value === undefined || value.trim() === '';
  }

  /**
   * Sort values ascending
   * @param values
   * @param field
   */
  sortValuesAscending(values: any, field: string) {
    const sortedValues = values.sort(function (nextValue: any, currentValue: any) {
      if (nextValue[field] < currentValue[field]) {
        return -1;
      } else if (nextValue[field] > currentValue[field]) {
        return 1;
      } else {
        return 0;
      }
    });

    return sortedValues;
  }

  /**
   * Sort values descending
   * @param values
   * @param field
   */
  sortValuesDescending(values: any, field: string) {
    const sortedValues = values.sort(function (nextValue: any, currentValue: any) {
      if (nextValue[field] > currentValue[field]) {
        return -1;
      } else if (nextValue[field] < currentValue[field]) {
        return 1;
      } else {
        return 0;
      }
    });

    return sortedValues;
  }

  /**
   * Get Task Duration
   * @param created
   */
  getTaskDuration(created) {
    const dt1 = new Date(created);
    const dt2 = new Date();
    return Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24));
  }

}
