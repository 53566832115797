import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * CanDeactivate Popup
 * Ask if user want to save, discard or cancel
 */
@Component({
  selector: 'ubi-popup-can-deactivate',
  templateUrl: './popup-can-deactivate.component.html',
  styleUrls: ['./popup-can-deactivate.component.scss'],
})
export class PopupCanDeactivateComponent {

  constructor(private activeModal: NgbActiveModal) {
  }

  /**
   * On close
   * @param reason
   */
  onClose(reason: string): void {
    this.activeModal.close(reason);
  }

  /**
   * On Dismiss
   * @param reason
   */
  onDismiss(reason: string): void {
    this.activeModal.dismiss(reason);
  }

}
