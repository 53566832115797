import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { LogoutComponent } from './@theme/auth/logout/logout.component';
import { AppAuthGuard } from './app.authguard';
import { CustomerAuthGuard } from './customer.authguard';
import { RedirectProfileComponent } from './@theme/auth/redirect-profile/redirect-profile.component';

const routes: Routes = [
  { path: 'auth/logout', component: LogoutComponent },
  { path: 'auth/profile', component: RedirectProfileComponent },
  {
    path: 'customer',
    loadChildren: 'app/customer/customer.module#CustomerModule',
    canActivate: [CustomerAuthGuard],
  },
  {
    path: 'pages',
    loadChildren: 'app/pages/pages.module#PagesModule',
    data: { breadcrumb: 'Home' },
    canActivate: [AppAuthGuard],
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
