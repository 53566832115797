import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SettingsService } from './settings.service';

/**
 * Shell Service
 */
@Injectable()
export class ShellService {

  private baseUrl: string;

  constructor(private http: HttpClient, private settings: SettingsService) { }

  /**
   * Set API base Url
   * @param url
   */
  setBaseUrl(url: string) {
    this.baseUrl = url;
  }

  /**
   * Get Menu
   */
  getMenu() {

    let url = this.settings.startUrl.base;
    if (this.baseUrl !== null && this.baseUrl !== undefined) {
      url = this.baseUrl;
    }

    return this.http
      .get(url + '/shell')
      .pipe(catchError(this.handleError));
  }

  /**
   * Encode text to Windows-1252
   */
  encodeWindows1252(text: string) {

    let url = this.settings.startUrl.base;
    if (this.baseUrl !== null && this.baseUrl !== undefined) {
      url = this.baseUrl;
    }

    return this.http
      .post(url + '/shell/Encode', { designation: text})
      .pipe(
        map((data: any) => data.designation),
        catchError(this.handleError),
      );
  }

  /**
   * Get last server version
   */
  getLastServerVersion() {
    let url = this.settings.startUrl.base;
    if (this.baseUrl !== null && this.baseUrl !== undefined) {
      url = this.baseUrl;
    }

    return this.http
      .get(url + '/shell/GetLastServerVersion')
      .pipe(catchError(this.handleError));
  }

  /**
   * Handle error
   * @param error
   */
  private handleError(error: HttpErrorResponse) {
    console.error('ShellService::handleError', error.error);
    return throwError(error);
  }

}
