import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { ActionsService } from '../../../@core/services/actions.service';
import { TranslateService } from '@ngx-translate/core';

/**
 *
 */
@Component({
  selector: 'ubi-layout-list-detail',
  templateUrl: './list-detail.layout.html',
  styleUrls: ['./list-detail.layout.scss'],
})
export class ListDetailLayoutComponent implements OnInit, OnDestroy {


  /**
   * Title
   */
  @Input()
  title: string;

  /**
   * Title for item / detail
   */
  @Input()
  titleDetail: string;

  /**
   * Options
   */
  @Input() options: any;

  /**
   * Filters
   */
  @Input() filters: any[];

  /**
   * New item event
   */
  @Output()
  new = new EventEmitter();

  /**
   * Filter event
   */
  @Output()
  filter = new EventEmitter();

  /**
   * Search event
   */
  @Output()
  search = new EventEmitter();

  /**
   * Advanced Search event
   */
  @Output()
  advancedSearch = new EventEmitter();

  /**
   * Erase filter event
   */
  @Output()
  eraseFilter = new EventEmitter();

  private savedSubscription: Subscription = null;
  private submitedSubscription: Subscription = null;
  private deletedSubscription: Subscription = null;
  private errorSubscription: Subscription = null;

  constructor(protected router: Router, protected route: ActivatedRoute,
    private actionsService: ActionsService,
    private toastrService: NbToastrService,
    private translate: TranslateService) {

  }

  ngOnInit() {
    const defaultOptions = {
      new: true,
    };

    this.options = Object.assign(defaultOptions, this.options);

    this.subscribeActions();

  }

  ngOnDestroy(): void {
    this.unsubscribeActions();
  }

  private subscribeActions() {
    this.savedSubscription = this.actionsService.saved.subscribe(() => this.onSaved());
    this.submitedSubscription = this.actionsService.submited.subscribe(() => this.onSubmited());
    this.deletedSubscription = this.actionsService.deleted.subscribe(() => this.onDeleted());
    this.errorSubscription = this.actionsService.error.subscribe(error => this.onError(error));
  }

  private unsubscribeActions() {
    this.savedSubscription.unsubscribe();
    this.submitedSubscription.unsubscribe();
    this.deletedSubscription.unsubscribe();
    this.errorSubscription.unsubscribe();
  }

  /**
   *
   * @param event
   */
  newClick(event: any) {
    this.new.emit();
  }

  /**
   *
   * @param event
   */
  filterClick(event: any) {
    this.filter.emit(event);
  }

  /**
   *
   * @param event
   */
  advancedSearchClick(event: any) {
    this.advancedSearch.emit(event);
  }

  /**
   *
   * @param event
   */
  eraseFilterClick(event: any) {
    this.eraseFilter.emit(event);
  }

  /**
   *
   * @param event
   */
  searchClick(event: any) {
    this.search.emit(event);
  }

  /**
   * Show Toastr after saved a register
   */
  private onSaved() {
    this.toastrService.success(this.translate.instant('Success'), this.translate.instant('Record saved'));
  }

  /**
   * Show Toastr after saved a register
   */
  private onSubmited() {
    this.toastrService.success(this.translate.instant('Submitted'), this.translate.instant('Request submitted'));
  }

  /**
   * Show toastr after deleted a register
   */
  private onDeleted() {
    this.toastrService.success(this.translate.instant('Success'), this.translate.instant('Record deleted'));
  }

  /**
   * Show toastr after deleted a register
   */
  private onError(error: any) {
    this.toastrService.danger(this.translate.instant('Error'), error);
  }

}
