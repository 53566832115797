import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs/Observable';
import { catchError, tap, map } from 'rxjs/operators';

import { ProcessDefinition } from '../../models/process-definition';
import { ActionsService } from './actions.service';
import { BaseService } from './base.service';
import { SettingsService } from './settings.service';
import { GenericSerializer } from '../../models/serializers/base/generic-serializer';
import { ExpressionFilter } from '../expression';

@Injectable()
export class WorkflowRestService extends BaseService {

  constructor(protected httpClient: HttpClient,
    protected logger: NGXLogger,
    protected settings: SettingsService,
    protected actionsService: ActionsService,
    private serializer: GenericSerializer<any>,
  ) {
    super(httpClient, logger, settings, actionsService);
  }

  getTasks(page: any, filter: ExpressionFilter, template: string): Observable<any> {
    // tslint:disable-next-line: max-line-length
    let endpoint = `${this.settings.startUrl.backoffice}/wf/getmygrouptasks`;

    if (template) {
      endpoint += `?template=${template}`;
    }

    if (filter) {
      page.Filter = filter;
    }

    return this.httpClient.post<any>(endpoint, page, { observe: 'response' })
      .pipe(
        map(response => {
          return {
            data: this.convertData(response.body),
            paging: {
              pageNumber: response.headers.get('x-paging-pagenumber'),
              pageSize: response.headers.get('x-paging-pagesize'),
              totalCount: response.headers.get('x-paging-totalcount'),
              pageCount: response.headers.get('x-paging-pagecount'),
            },
          };
        }),
        catchError(this.handleError('getTasks', null)),
      );
  }

  /**
   * Serialize data
   * @param data
   */
  private convertData(data: any): any {
    return data.map((item: any) => this.serializer.jsonToObject(item));
  }

  /**
   * Get task
   * @param taskId
   */
  getTask(taskId: string): Observable<any> {
    const endpoint = `${this.settings.startUrl.backoffice}/wf/gettask/${taskId}`;
    return this.httpClient.get<any>(endpoint)
      .pipe(
        tap(form => this.log(`fetched task`)),
        catchError(this.handleError('getTask', null)),
      );
  }

  /**
   * Get instance
   * @param id
   */
  getInstance(id: string): Observable<any> {
    const endpoint = `${this.engineRestUrl}/history/variable-instance?processInstanceId=${id}`;
    return this.httpClient.get<any>(endpoint)
      .pipe(
        tap(form => this.log(`fetched instance`)),
        catchError(this.handleError('getInstance', [])),
      );
  }

  /**
   * Get task's form key
   * @param taskId
   */
  getTaskFormKey(taskId: string): Observable<any> {
    const endpoint = `${this.engineRestUrl}/task/${taskId}/form`;
    return this.httpClient.get<any>(endpoint)
      .pipe(
        tap(form => this.log(`fetched taskform`)),
        catchError(this.handleError('getTaskFormKey', [])),
      );
  }

  /**
   * Get Task by Process Instance Id
   * @param processInstanceId
   */
  getTaskByInstance(processInstanceId: string): Observable<any> {
    const endpoint = `${this.settings.startUrl.backoffice}/wf/GetTaskByInstance/${processInstanceId}`;

    return this.httpClient.get<any>(endpoint)
      .pipe(
        tap(form => this.log(`fetched task`)),
        catchError(this.handleError('getTaskByInstance', [])),
      );
  }

  /**
   *
   * @param taskId
   * @param variableNames
   */
  getVariablesForTaskById(taskId: string): Observable<any> {
    const endpoint = `${this.engineRestUrl}/task/${taskId}/form-variables`;
    return this.httpClient.get<any>(endpoint)
      .pipe(
        tap(form => this.log(`fetched variables`)),
        catchError(this.handleError('getVariablesForTask', [])),
      );
  }

  /**
   *
   * @param taskId
   * @param variableNames
   */
  getVariablesForTask(taskId: string, variableNames: string): Observable<any> {
    const endpoint = `${this.engineRestUrl}/task/${taskId}/form-variables?variableNames=${variableNames}`;
    return this.httpClient.get<any>(endpoint)
      .pipe(
        tap(form => this.log(`fetched variables`)),
        catchError(this.handleError('getVariablesForTask', [])),
      );
  }

  /**
   *
   * @param processDefinitionKey
   */
  getTasksHistory(processDefinitionKey: string): Observable<any> {
    const endpoint = `${this.settings.startUrl.backoffice}/wf/GetProcessInstanceHistoric?processDefinitionKey=${processDefinitionKey}`;

    return this.httpClient.get<any>(endpoint)
      .pipe(
        tap(form => this.log(`fetched tasks`)),
        catchError(this.handleError('getTasksHistory', [])),
      );
  }

  postCompleteTask(taskId: string, variables: Object): Observable<any> {
    const endpoint = `${this.engineRestUrl}/task/${taskId}/complete`;
    return this.httpClient.post<any>(endpoint, variables)
      .pipe(
        tap(tasks => {
          this.log(`posted complete task`);
          this.actionsService.submitedAction();
        }),
        catchError(this.handleError('postCompleteTask', [])),
      );
  }

  postSubmitTask(taskId: string, variables: Object): Observable<any> {
    const endpoint = `${this.engineRestUrl}/task/${taskId}/submit-form`;
    return this.httpClient.post<any>(endpoint, variables)
      .pipe(
        tap(tasks => {
          this.log(`posted submit-form task`);
          this.actionsService.submitedAction();
        }),
        catchError(this.handleError('postSubmitTask', [])),
      );
  }

  /**
   * Get task's start form key
   * @param taskId
   */
  getTaskStartFormKey(processDefinitionKey: string): Observable<any> {
    const endpoint = `${this.engineRestUrl}/process-definition/key/${processDefinitionKey}/startForm`;
    return this.httpClient.get<any>(endpoint)
      .pipe(
        tap(form => this.log(`fetched taskform`)),
        catchError(this.handleError('getTaskFormKey', [])),
      );
  }

  getProcessDefinitions(): Observable<ProcessDefinition[]> {
    return this.httpClient.get<ProcessDefinition[]>(this.engineRestUrl + 'process-definition?latestVersion=true')
      .pipe(
        tap(processDefinitions => this.log(`fetched processDefinitions`)),
        catchError(this.handleError('getProcessDefinitions', [])),
      );
  }

  postProcessInstance(processDefinitionKey: string, variables: any): Observable<any> {
    const endpoint = `${this.engineRestUrl}/process-definition/key/${processDefinitionKey}/start`;
    return this.httpClient.post<any>(endpoint, variables)
      .pipe(
        tap(processDefinitions => {
          this.log(`posted process instance`);
          this.actionsService.submitedAction();
        }),
        catchError(this.handleError('postProcessInstance', [])),
      );
  }

  deployProcess(fileToUpload: File): Observable<any> {
    const endpoint = `${this.settings.startUrl.workflow}/deployment/create`;
    const formData = new FormData();

    formData.append('fileKey', fileToUpload, fileToUpload.name);

    return this.httpClient.post(endpoint, formData);
  }


  getProcessDiagram(processDefinitionKey: string): Observable<any> {
    const endpoint = `${this.settings.startUrl.workflow}/process-definition/key/${processDefinitionKey}/xml`;

    return this.httpClient.get<any>(endpoint)
      .pipe(
        tap(diagram => this.log(`fetched diagram`)),
        catchError(this.handleError('getProcessDiagram', [])),
      );
  }

  getCaseDiagram(caseDefinitionKey: string): Observable<any> {
    const endpoint = `${this.settings.startUrl.workflow}/case-definition/key/${caseDefinitionKey}/xml`;

    return this.httpClient.get<any>(endpoint)
      .pipe(
        tap(diagram => this.log(`fetched diagram`)),
        catchError(this.handleError('getCaseDiagram', [])),
      );
  }
}
