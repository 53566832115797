import { Operator } from './operator';
import { ExpressionFilter } from './expressionFilter';
import { Serializable } from './serializable';

export class ExpressionRule implements Serializable<ExpressionRule> {
    field: string;
    value: any;
    operator: Operator;
    filter: ExpressionFilter;

    constructor(field: string = null, operator: Operator = null, value: any = null) {
        this.field = field;
        this.operator = operator;
        this.value = value;
    }

    deserialize(input: any) {
        this.field = input.field;
        this.operator = input.Operator;
        this.value = input.value;
        this.filter = new ExpressionFilter(input.filter.condition).deserialize(input.filter);
        return this;
    }

    addFilter(filter: ExpressionFilter) {
        this.filter = filter;

        this.field = null;
        this.filter = null;
        this.operator = null;
    }

}
