// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { KeycloakConfig } from 'keycloak-angular';

// Add here your keycloak setup infos
const keycloakConfig: KeycloakConfig = {
  url: 'http://localhost:4040/auth/',
  realm: 'soprema',
  clientId: 'workflow',
};

export const environment = {
  production: true,

  keycloak: keycloakConfig,

  workflowUrl: '/engine-rest/',

  formsRepositoryUrl: '/api/',

  backofficeUrl: '/api/',

  contentUrl: 'http://dataspm.ubiquity.pt/api/',

  storageUrl: 'http://192.168.1.211:5010/api/storage/',

  tokenSquidex: 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjkxRkRENEVCRDYwNjMxNURFREI4MENEMDkzMERFRkZBMjFEREE2NkIiLCJ0eXAiOiJKV1QiLCJ4NXQiOiJrZjNVNjlZR01WM3R1QXpRa3czdi1pSGRwbXMifQ.eyJuYmYiOjE1NjIzMzQxODMsImV4cCI6MTU2NDkyNjE4MywiaXNzIjoiaHR0cDovL2RhdGFzcG0udWJpcXVpdHkucHQvaWRlbnRpdHktc2VydmVyIiwiYXVkIjpbImh0dHA6Ly9kYXRhc3BtLnViaXF1aXR5LnB0L2lkZW50aXR5LXNlcnZlci9yZXNvdXJjZXMiLCJzcXVpZGV4LWFwaSJdLCJjbGllbnRfaWQiOiJ3YXJyYW50aWVzOmRlZmF1bHQiLCJzY29wZSI6WyJzcXVpZGV4LWFwaSJdfQ.EA9cpZY9Af-ZRaM6FF4ZSn1-MXL17iSy7scpLKDjbvZ_1EeDCEJ58x114CpKPXbtQ766WMWrMwj1I6uAgezSSeOgUJ1oOeA1tieVwAvv6na3szQD37fD1FAX7BJNFo0Z3Mmrwo-2xL_Hb0tzhu23glOj-0eqRT1Fq2OuC5_cC1AElCtxokkNDT3Vfn_BM90EQomBoxVEzfTwhp54e9NFsYH3WaipORbpjlkGHvaV0t8_GraM-O6HreqqCvbDS5dY3_26zahu36anoZgEQEDcQckBnm4XQRAi0qTcpz5q1UiTvJykPpvVeaEY_HfIAyjxEu7zoVce3L12Y3_qac7ibg',

};
