import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { catchError, tap } from 'rxjs/operators';

import { Condition, ExpressionFilter, ExpressionRule, Operator } from '../expression';
import { ActionsService } from './actions.service';
import { BaseService } from './base.service';
import { SettingsService } from './settings.service';

/**
 * Tags Service
 */
@Injectable()
export class TagsService extends BaseService {

    constructor(protected httpClient: HttpClient,
        protected logger: NGXLogger,
        protected settings: SettingsService,
        protected actionsService: ActionsService,
      ) {
        super(httpClient, logger, settings, actionsService);
      }

  searchTags(searchKey) {
    const endpoint = `${this.webApiUrl}/tags/Get`;

    const newfilter = new ExpressionFilter(Condition.AND);
    newfilter.addRule(new ExpressionRule('Designation', Operator.Contains, searchKey));

    return this.httpClient.post<any[]>(endpoint, { Filter: newfilter })
      .pipe(
        tap(form => this.logger.trace(`fetched tags`)),
        catchError(this.handleError('searchTags', [])),
      );
  }
}
