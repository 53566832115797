import { Injectable } from '@angular/core';
import { KeycloakEvent } from 'keycloak-angular';
import { Observable, Subject } from 'rxjs';

export interface EventItem { id: number; event: KeycloakEvent; timestamp: number; }

@Injectable({
  providedIn: 'root',
})
export class EventStackService {
  private nextId: number;
  private eventItemStack: EventItem[];
  private eventTriggerSource = new Subject<EventItem[]>();

  public eventTriggered: Observable<EventItem[]>;

  constructor() {
    this.nextId = 0;
    this.eventItemStack = [];
    this.eventTriggered = this.eventTriggerSource.asObservable();
  }

  public purgeEventItem(eventItemId: number): void {
    const idx = this.eventItemStack.findIndex(eventItem => eventItem.id === eventItemId);
    this.eventItemStack.splice(idx, 1);
  }

  public triggerEvent(event: KeycloakEvent) {
    this.nextId += 1;

    this.eventItemStack.push({
      event,
      id: this.nextId,
      timestamp: Date.now(),
    });

    this.eventTriggerSource.next(this.eventItemStack);
  }

  public get eventStack(): EventItem[] {
    return this.eventItemStack;
  }
}
