import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

/**
 * Error Handling Service
 */
@Injectable()
export class ErrorHandlingService {

  constructor() { }

  /**
   * Creates an error message depending on the error type
   * @param c
   */
  createErrorMessage(c: AbstractControl): string {
    /* Creates the error list */
    const errorList: Array<string> = [];
    /* Traverses the errors */
    for (const errorName in c.errors) {
      if (c.errors.hasOwnProperty(errorName)) {
        /* Gets the current error */
        const error = c.errors[errorName];
        /* Checks for the error type and stores the appropriate message */
        switch (errorName) {
          case ('required'):
            errorList.push('Campo obrigatório');
            break;
          case ('maxlength'):
            errorList.push('Insira no máximo ' + error.requiredLength + ' caracteres');
            break;
          case ('minlength'):
            errorList.push('Insira no mínimo ' + error.requiredLength + ' caracteres');
            break;
          case ('pattern'):
            errorList.push('Formato inválido');
            break;
          case ('validateMonthYear'):
            errorList.push('Formato inválido para data "mês-ano" (MM-AAAA)');
            break;
        }

      }

    }
    /* Checks whether there is only one error */
    if (errorList.length === 1) {
      /* Returns the only error */
      return errorList[0];
      /* There is more than one error */
    } else if (errorList.length > 1) {
      /* Returns the joined errors as a simple list */
      return '- ' + errorList.join('\n- ') + '';
    }
  }

  /**
   * Checks whether the given form control has an error
   * @param c
   */
  hasError(c: AbstractControl): boolean {
    return c && (c.touched || c.dirty) && !c.valid;
  }
}
