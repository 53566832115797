import { HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse,
         HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse,
         HttpUserEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ExcludedUrlRegex } from 'keycloak-angular/lib/core/interfaces/keycloak-options';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { take, mergeMap, catchError, switchMap, finalize, filter } from 'rxjs/operators';
import { CustomerAuthService } from '../../customer/customer-auth.service';

/**
 * This interceptor includes the bearer by default in all HttpClient requests.
 *
 * If you need to exclude some URLs from adding the bearer, please, take a look
 * at the {@link KeycloakOptions} bearerExcludedUrls property.
 */
@Injectable()
export class AuthBearerInterceptor implements HttpInterceptor {

  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private keycloak: KeycloakService, private customerAuthService: CustomerAuthService) { }

  /**
   * Checks if the url is excluded from having the Bearer Authorization
   * header added.
   *
   * @param req http request from @angular http module.
   * @param excludedUrlRegex contains the url pattern and the http methods,
   * excluded from adding the bearer at the Http Request.
   */
  private isUrlExcluded(
    { method, url }: HttpRequest<any>,
    { urlPattern, httpMethods }: ExcludedUrlRegex,
  ): boolean {
    const httpTest =
      httpMethods.length === 0 ||
      httpMethods.join().indexOf(method.toUpperCase()) > -1;

    const urlTest = urlPattern.test(url);

    return httpTest && urlTest;
  }

  /**
   * Add token to http header
   * @param req
   * @param token
   */
  private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone(
      {
        setHeaders: { Authorization: `Bearer ${token}` },
      },
    );
  }

  /**
   * Intercept implementation that checks if the request url matches the excludedUrls.
   * If not, adds the Authorization header to the request.
   *
   * @param request
   * @param next
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

    const { excludedUrls } = this.keycloak;

    // Get token doesn't need bearer
    const excludeToken: ExcludedUrlRegex[] = [{
      urlPattern: new RegExp('/api/token', 'i'),
      httpMethods: [],
    }];

    let shallPass: boolean = excludeToken.findIndex(item => this.isUrlExcluded(request, item)) > -1;
    if (shallPass) {
      return next.handle(request);
    }

    let authenticated: boolean = false;

    try {
      authenticated = this.keycloak.getUsername().trim() !== '';
    } catch (error) {
      authenticated = false;
    }

    let customerRequest = request.clone();

    // Verify if it's a customer then add info to header
    if (this.customerAuthService.customerId !== undefined && this.customerAuthService.branch !== undefined) {
      customerRequest = request.clone({ setHeaders: { 'CustomerId': this.customerAuthService.customerId } })
        .clone({ setHeaders: { 'Branch': this.customerAuthService.branch } });
    }

    if (!authenticated) {

      return next
        .handle(this.addToken(customerRequest, this.customerAuthService.token))
        .pipe(
          catchError(error => {
            if (error instanceof HttpErrorResponse) {
              switch ((<HttpErrorResponse>error).status) {
                case 400:
                  return this.handle400Error(error);
                case 401:
                  return this.handle401Error(customerRequest, next);
                default:
                  return throwError(error);
              }
            } else {
              return throwError(error);
            }
          }));

    } else {

      shallPass = excludedUrls.findIndex(item => this.isUrlExcluded(request, item)) > -1;
      if (shallPass) {
        return next.handle(request);
      }

      return this.keycloak.addTokenToHeader(customerRequest.headers).pipe(
        mergeMap(headersWithBearer => {
          const kcReq = request.clone({ headers: headersWithBearer });

          return next.handle(kcReq);
        }),
      );

    }

  }

  handle400Error(error: HttpErrorResponse) {
    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      //return this.logoutUser();
    }

    return throwError(error);
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.customerAuthService.getRefreshToken('').pipe(
        switchMap((newToken: any) => {
          if (newToken) {
            this.tokenSubject.next(newToken.accessToken);
            return next.handle(this.addToken(req, newToken.accessToken));
          }

          // If we don't get a new token, we are in trouble so logout.
          // return this.logoutUser();
        }),
        catchError(error => {
          // If there is an exception calling 'refreshToken', bad news so logout.
          // return this.logoutUser();
          return throwError(error);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }));
    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(req, token));
        }));
    }
  }
}
