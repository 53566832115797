import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ubi-layout-detail-old',
  templateUrl: './ubiLayoutDetail.component.html',
  styleUrls: ['./ubiLayoutDetail.component.scss'],
})
export class UbiLayoutDetailComponent implements OnInit {

  @Input() status: any;
  @Input() parentForm: any;

  @Input() title: string;

  @Output()
  save = new EventEmitter();

  @Output()
  delete = new EventEmitter();

  @Output()
  cancel = new EventEmitter();

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  public saveClick(event) {
    this.save.emit(true);
  }

  public deleteClick(event, content) {

    this.modalService.open(content, { backdrop: 'static', keyboard: false }).result.then((result) => {
      if (result === 'Delete') {
        this.delete.emit(true);
      }
    });

  }

  public cancelClick(event) {
    this.cancel.emit(true);
  }

}
