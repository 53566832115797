import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NbSecurityModule } from '@nebular/security';
import {
  NbAccordionModule,
  NbActionsModule,
  NbAlertModule,
  NbButtonModule,
  NbCalendarKitModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbCardModule,
  NbChatModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbPopoverModule,
  NbProgressBarModule,
  NbRadioModule,
  NbRouteTabsetModule,
  NbSearchModule, NbSelectModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbStepperModule,
  NbTabsetModule,
  NbThemeModule,
  NbToastrModule,
  NbTooltipModule,
  NbUserModule,
  NbWindowModule,
  NbIconModule,
} from '@nebular/theme';
import { StateService } from '../@core/data/state.service';
import {
  BreadcrumbComponent,
  DetailHeaderComponent,
  FooterComponent,
  HeaderComponent,
  ThemeSettingsComponent,
  PagerComponent,
  // TermsConditionsComponent,
} from './components';
import {
  CleanLayoutComponent,
  DetailLayoutComponent,
  ListDetailLayoutComponent,
  MainLayoutComponent,
  TabsDetailLayoutComponent,
  ListLayoutComponent,
  UbiLayoutDetailComponent,
} from './layouts';
import {
  CapitalizePipe,
  NumberWithCommasPipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  PrettyPrintPipe,
  EvaIconsPipe,
} from './pipes';

import { LogoutComponent } from './auth/logout/logout.component';
import { SOPREMA_THEME } from './styles/theme.soprema';
import { TranslateModule } from '@ngx-translate/core';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { AlphabetSearchComponent } from './components/alphabet-search/alphabet-search.component';
import { RedirectProfileComponent } from './auth/redirect-profile/redirect-profile.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

const BASE_MODULES = [CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  NgxDatatableModule,
  TranslateModule,
];

const NB_MODULES = [
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NgbModule,
  NbSecurityModule,
  NbProgressBarModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbStepperModule,
  NbButtonModule,
  NbListModule,
  NbToastrModule,
  NbInputModule,
  NbAccordionModule,
  NbDatepickerModule,
  NbDialogModule,
  NbWindowModule,
  NbAlertModule,
  NbSpinnerModule,
  NbRadioModule,
  NbSelectModule,
  NbChatModule,
  NbTooltipModule,
  NbCalendarKitModule,
  NbIconModule,
];

const COMPONENTS = [
  HeaderComponent,
  DetailHeaderComponent,
  FooterComponent,
  ThemeSettingsComponent,
  MainLayoutComponent,
  CleanLayoutComponent,
  ListLayoutComponent,
  ListDetailLayoutComponent,
  DetailLayoutComponent,
  TabsDetailLayoutComponent,
  LogoutComponent,
  BreadcrumbComponent,
  PagerComponent,
  AlphabetSearchComponent,
  UbiLayoutDetailComponent,
  TermsConditionsComponent,
];

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  EvaIconsPipe,
  PrettyPrintPipe,
];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: 'soprema',
    },
    [ SOPREMA_THEME ],
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
  ...NbDatepickerModule.forRoot().providers,
  ...NbDialogModule.forRoot().providers,
  ...NbWindowModule.forRoot().providers,
  ...NbToastrModule.forRoot().providers,
];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES],
  exports: [...BASE_MODULES, ...PIPES, ...COMPONENTS, ...NB_MODULES],
  declarations: [...COMPONENTS, ...PIPES, RedirectProfileComponent],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [...NB_THEME_PROVIDERS],
    };
  }
}
