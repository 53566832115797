import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PopupCanDeleteComponent } from '../../../@core/popups/popup-can-delete';

@Component({
  selector: 'ubi-layout-detail',
  templateUrl: './detail.layout.html',
  styleUrls: ['./detail.layout.scss'],
})
export class DetailLayoutComponent implements OnInit {

  /**
   * Options
   */
  @Input() options: any;

  /**
   * Status
   */
  @Input() showOptions: boolean = true;

  /**
   * Status
   */
  @Input() status: any;

  /**
   * Parent Form
   */
  @Input() parentForm: any;

  /**
   * Title
   */
  @Input()
  title: string;

  /**
   * Save event
   */
  @Output()
  save = new EventEmitter();

  /**
   * Delete Event
   */
  @Output()
  delete = new EventEmitter();

  /**
   * Cancel event
   */
  @Output()
  cancel = new EventEmitter();

  constructor(private modalService: NgbModal, private route: ActivatedRoute) { }

  ngOnInit() {
    const titleFromRoute = this.route.snapshot.data['title'];

    // If both exists them @Input overrides Route
    if (titleFromRoute !== null && titleFromRoute !== undefined
      && (this.title === null || this.title === undefined)) {
      this.title = titleFromRoute;
    }

  }

  /**
   * Save item
   * @param event
   */
  saveClick(event) {
    this.save.emit(true);
  }

  /**
   * Delete item
   * @param event
   * @param content
   */
  deleteClick(event) {

    this.modalService.open(PopupCanDeleteComponent, { backdrop: 'static', keyboard: false }).result.then((result) => {
      if (result === 'Delete') {
        this.delete.emit(true);
      }
    });

  }

  /**
   * Cancel item edit
   * @param event
   */
  cancelClick(event) {
    this.cancel.emit(true);
  }
}
