import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActionsService {

  private submitSource = new Subject<string>();
  private newSource = new Subject<boolean>();
  private previewSource = new Subject<boolean>();
  private saveSource = new Subject<boolean>();
  private saveAsSource = new Subject<boolean>();
  private editSource = new Subject<boolean>();
  private deleteSource = new Subject<boolean>();
  private searchSource = new Subject<boolean>();
  private exportSource = new Subject<boolean>();
  private importSource = new Subject<boolean>();

  private errorSource = new Subject<any>();
  private selectedSource = new Subject<string>();

  private submitedSource = new Subject<boolean>();
  private savedSource = new Subject<boolean>();
  private deletedSource = new Subject<boolean>();
  private exportedSource = new Subject<boolean>();
  private importedSource = new Subject<boolean>();

  private languageChangeSource = new Subject<string>();
  private formioReadySource = new Subject<boolean>();

  constructor() { }

  get submit() {
    return this.submitSource.asObservable();
  }

  get submited() {
    return this.submitedSource.asObservable();
  }

  get new() {
    return this.newSource.asObservable();
  }

  get preview() {
    return this.previewSource.asObservable();
  }

  get selected() {
    return this.selectedSource.asObservable();
  }

  get save() {
    return this.saveSource.asObservable();
  }

  get saveAs() {
    return this.saveAsSource.asObservable();
  }

  get saved() {
    return this.savedSource.asObservable();
  }

  get export() {
    return this.exportSource.asObservable();
  }

  get exported() {
    return this.exportedSource.asObservable();
  }

  get import() {
    return this.importSource.asObservable();
  }

  get imported() {
    return this.importedSource.asObservable();
  }

  get delete() {
    return this.deleteSource.asObservable();
  }

  get deleted() {
    return this.deletedSource.asObservable();
  }

  get error() {
    return this.errorSource.asObservable();
  }

  get languageChange() {
    return this.languageChangeSource.asObservable();
  }

  get formioReady() {
    return this.formioReadySource.asObservable();
  }

  /**
   * Submit Action
   * @param mission
   */
  submitAction(mission: string) {
    this.submitSource.next(mission);
  }

  /**
   * Submit Action
   * @param mission
   */
  submitedAction() {
    this.submitedSource.next(true);
  }

  /**
   * New Action
   */
  newAction() {
    this.newSource.next(true);
  }

  /**
   * Preview Action
   */
  previewAction() {
    this.previewSource.next(true);
  }

  /**
   * Selected Action
   */
  selectedAction(selected: string) {
    this.selectedSource.next(selected);
  }

  /**
   * Edit Action
   */
  editAction() {
    this.editSource.next(true);
  }

  /**
   * Delete Action
   */
  deleteAction() {
    this.deleteSource.next(true);
  }

  /**
   * Search Action
   */
  searchAction() {
    this.searchSource.next(true);
  }

  /**
   * Save Action
   */
  saveAction() {
    this.saveSource.next(true);
  }

  /**
   * Save As Action
   */
  saveAsAction() {
    this.saveAsSource.next(true);
  }

  /**
   * Export Action
   */
  exportAction() {
    this.exportSource.next(true);
  }

  /**
   * Exported Action
   */
  exportedAction() {
    this.exportedSource.next(true);
  }

  /**
   * Import Action
   */
  importAction() {
    this.importSource.next(true);
  }

  /**
   * Imported Action
   */
  importedAction() {
    this.importedSource.next(true);
  }

  /**
   * Saved Action
   */
  savedAction() {
    this.savedSource.next(true);
  }

  /**
   * Deleted Action
   */
  deletedAction() {
    this.deletedSource.next(true);
  }

  /**
   * Error Action
   * @param error
   */
  errorAction(error: any): any {
    this.errorSource.next(error);
  }

  languageChangeAction(language: string) {
    this.languageChangeSource.next(language);
  }

  /**
   * Formio Ready Action
   */
  formioReadyAction(): any {
    this.formioReadySource.next();
  }
}
