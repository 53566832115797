import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NbAuthModule, NbDummyAuthStrategy } from '@nebular/auth';
import { NbRoleProvider, NbSecurityModule } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { DataModule } from './data/data.module';
import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  PopupCanDeactivateComponent, PopupCanDeleteComponent,
  PopupComponent, PopupAdvancedSearchComponent,
} from './popups';
import { ActionsService } from './services/actions.service';
import { WorkflowRestService } from './services/workflow-rest.service';
import { EventStackService } from './services/event-stack.service';
import { FormsJsonService } from './services/forms.json.service';
import { ProcessesService } from './services/processes.service';
import { SettingsService } from './services/settings.service';
import { StoreDataService } from './services/store-data-service';
import { TagsService } from './services/tags.service';
import { AuthBearerInterceptor } from './utils/interceptors.auth';
import { AnalyticsService } from './utils/analytics.service';
import { ErrorHandlingService } from './utils/error-handling.service';
import { HttpErrorInterceptor } from './utils/interceptor';
import { UtilsService } from './utils/utils.service';
import { NbCardModule } from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { QueryBuilderModule } from 'angular2-query-builder';
import { TranslateModule } from '@ngx-translate/core';

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

const POPUPS = [
  PopupComponent,
  PopupCanDeactivateComponent,
  PopupCanDeleteComponent,
  PopupAdvancedSearchComponent,
];

export const NB_CORE_PROVIDERS = [
  ...DataModule.forRoot().providers,
  ...NbAuthModule.forRoot({

    strategies: [
      NbDummyAuthStrategy.setup({
        name: 'email',
        delay: 3000,
      }),
    ],
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,
  {
    provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true,
  },
  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  {
    provide: HTTP_INTERCEPTORS, useClass: AuthBearerInterceptor, multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true,
  },
  AnalyticsService,
  EventStackService,
  WorkflowRestService,
  FormsJsonService,
  ActionsService,
  SettingsService,
  TagsService,
  UtilsService,
  ProcessesService,
  StoreDataService,
  ErrorHandlingService,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NbCardModule,
    QueryBuilderModule,
    TranslateModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [...POPUPS],
  entryComponents: [...POPUPS],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
