import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, throwError } from 'rxjs';

import { ActionsService } from './actions.service';
import { SettingsService } from './settings.service';

@Injectable()
export class BaseService {

  protected engineRestUrl = this.settings.startUrl.workflow;
  protected webApiUrl = this.settings.startUrl.backoffice;

  constructor(protected httpClient: HttpClient,
    protected logger: NGXLogger,
    protected settings: SettingsService,
    protected actionsService: ActionsService,
  ) { }

  /**
   * Handle error
   * @param operation
   * @param result
   */
  protected handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {

      this.logger.error(operation, result, error);

      this.actionsService.errorAction(result);

      return throwError(error);
      // Let the app keep running by returning an empty result.
      // return of(result as T);
    };
  }

  protected log(message: string) {
    this.logger.debug(message);
  }

}
