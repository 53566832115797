import { KeycloakService } from 'keycloak-angular';

import { SettingsService } from './@core/services';
import { EventStackService } from './@core/services/event-stack.service';

export function InitializerKeycloak(keycloakService: KeycloakService,
  eventStackService: EventStackService, settingsService: SettingsService): () => Promise<any> {

  return (): Promise<any> => {

    return new Promise(async (resolve, reject) => {
      try {

        keycloakService.keycloakEvents$.subscribe(event => {
          eventStackService.triggerEvent(event);
        });

        await keycloakService.init({
          config: settingsService.keycloakConfig,
          enableBearerInterceptor: false,
          initOptions: {
            onLoad: 'check-sso',
            checkLoginIframe: false,
          },
          bearerExcludedUrls: [ '/api/token', '/assets' , 'http://dataspm.ubiquity.pt/api', 'http://datawt.soprema.it/api'],
          loadUserProfileAtStartUp: true,
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };
}


export function InitializeSettings(settingsService: SettingsService) {
  return settingsService.formioAppConfig();
}
