import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { BaseService } from '../@core/services/base.service';
import { SettingsService } from '../@core/services/settings.service';
import { ActionsService } from '../@core/services/actions.service';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class CustomerAuthService extends BaseService {

  /**
   * Customer Id
   */
  customerId: string;

  /**
   * Customer Branch
   */
  branch: string;

  /**
   * Refresh Token
   */
  token: string;

  private jwtHelper = new JwtHelperService();

  constructor(protected http: HttpClient,
    protected logger: NGXLogger,
    protected settings: SettingsService,
    protected actionsService: ActionsService,
  ) {
    super(http, logger, settings, actionsService);
  }

  /**
   * Token is expired
   */
  get isExpired(): boolean {
    return this.jwtHelper.isTokenExpired(this.token);
  }

  /**
   * Get initial token with customer data
   * @param guid
   */
  getRefreshToken(guid: string): Observable<any> {
    let endpoint = `${this.settings.backofficeUrl}token`;

    if (guid !== '') {
      endpoint += `/${guid}`;
    }

    return this.http.get<any>(endpoint)
      .pipe(
        tap(response => {
          this.logger.trace(response);
          this.customerId = response.customerId;
          this.branch = response.branch;
          this.token = response.accessToken;

          return response;

        }),
        catchError(this.handleError('getToken', null)),
      );
  }

}
