import {Injectable} from '@angular/core';

@Injectable()
export class StoreDataService {
    private cache = new Map<string, [Date, any]>();


    get keys() {
      return Array.from(this.cache.keys());
    }

    /**
     * Get object stored in cache
     * @param key
     */
    get(key): any {
      const tuple = this.cache.get(key);
      if (!tuple) {
          return null;
      }

      const expires = tuple[0];
      const data = tuple[1];

      // Don't observe expired keys
      const now = new Date();
      if (expires && expires.getTime() < now.getTime()) {
        this.cache.delete(key);
        return null;
      }

      return data;
    }

    /**
     * Store object in cache
     * @param key
     * @param value
     * @param ttl
     */
    set(key, value, ttl = null) {
      let expires = null;

      if (ttl) {
        expires = new Date();
        expires.setSeconds(expires.getSeconds() + ttl);
      }

      this.cache.set(key, [expires, value]);
    }

    /**
     * Clear cache
     */
    clear() {
      this.cache.clear();
    }

    /**
     * Remove stored object from cache
     * @param key
     */
    delete(key: string) {
      this.cache.delete(key);
    }
}
