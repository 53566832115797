import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import { CustomerAuthService } from './customer/customer-auth.service';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class CustomerAuthGuard extends KeycloakAuthGuard {
    constructor(protected router: Router,
        protected keycloakAngular: KeycloakService,
        protected customerService: CustomerAuthService,
        private logger: NGXLogger) {
        super(router, keycloakAngular);
    }

    isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        return new Promise(async (resolve, reject) => {

            this.logger.debug(`CustomerAuthGuard - isAccessAllowed`);
            return resolve(true);
        });
    }
}
