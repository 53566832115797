import { Component, Input, OnInit } from '@angular/core';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { KeycloakService } from 'keycloak-angular';
import { NGXLogger } from 'ngx-logger';

import { LayoutService } from '../../../@core/data/layout.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
@Component({
  selector: 'ubi-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  fullName: string;
  picture = 'assets/images/user.svg';

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private logger: NGXLogger,
    private analyticsService: AnalyticsService,
    private layoutService: LayoutService,
    private keycloak: KeycloakService) {
  }

  ngOnInit() {
    this.keycloak
      .isLoggedIn()
      .then(loggedIn => {
        if (loggedIn) {
          this.keycloak.loadUserProfile()
            .then(profile => {
              if (profile.firstName && profile.lastName) {
                this.fullName = profile.firstName + ' ' + profile.lastName;
              } else if (profile.firstName) {
                this.fullName = profile.firstName;
              } else {
                this.fullName = profile.username;
              }
            })
            .catch(reason => {
              this.fullName = this.keycloak.getUsername();
              this.logger.warn(reason);
            });
        }
      })
      .catch(reason => this.logger.warn(reason));
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

}
