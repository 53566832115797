import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import { CustomerAuthService } from './customer/customer-auth.service';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class AppAuthGuard extends KeycloakAuthGuard {
    constructor(protected router: Router,
        protected keycloakAngular: KeycloakService,
        protected customerService: CustomerAuthService,
        private logger: NGXLogger) {
        super(router, keycloakAngular);
    }

    isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        return new Promise(async (resolve, reject) => {

            this.logger.debug(`AppAuthGuard - isAccessAllowed`);
            if (!this.authenticated && this.customerService.customerId === undefined) {
                this.keycloakAngular.login();
                return;
            }

            const requiredRoles = route.data.roles;
            if (!requiredRoles || requiredRoles.length === 0) {
                return resolve(true);
            } else {
                if (!this.roles || this.roles.length === 0) {
                    resolve(false);
                }
                let granted: boolean = false;
                for (const requiredRole of requiredRoles) {
                    if (this.roles.indexOf(requiredRole) > -1) {
                        granted = true;
                        break;
                    }
                }
                resolve(granted);
            }
        });
    }
}
