import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ubi-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss'],
})
export class PagerComponent {

  @Input() footerHeight: number;
  @Input() rowCount: number;
  @Input() pageSize: number;
  @Input() offset: number;
  @Input() pagerLeftArrowIcon: string;
  @Input() pagerRightArrowIcon: string;
  @Input() pagerPreviousIcon: string;
  @Input() pagerNextIcon: string;
  @Input() totalMessage: string;

  @Input() selectedCount: number = 0;
  @Input() selectedMessage: string | boolean;

  @Output() page: EventEmitter<any> = new EventEmitter();

  get isVisible(): boolean {
    return this.rowCount / this.pageSize > 1;
  }

  get curPage(): number {
    return this.offset + 1;
  }

  /**
   * The component triggered a page event.
   */
  onPageChange(event: any) {
    this.offset = event.page - 1;

    this.page.emit({
      count: this.rowCount,
      pageSize: this.pageSize,
      limit: this.pageSize,
      offset: this.offset,
    });

  }

}
