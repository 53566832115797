import { Condition } from './condition';
import { ExpressionRule } from './expressionRule';
import { Serializable } from './serializable';


export class ExpressionFilter implements Serializable<ExpressionFilter> {
    condition: Condition;
    rules: Array<ExpressionRule>;

    constructor(condition: Condition = Condition.AND, rules: Array<ExpressionRule> = null) {
        this.condition = condition;
        this.rules = rules;
    }

    deserialize(input: any) {
        this.condition = input.condition;
        this.rules = input.rules;
        return this;
    }

    addRule(rule: ExpressionRule) {

        if (this.rules === undefined || this.rules === null) {
            this.rules = new Array();
        }

        this.rules.push(rule);
    }
}
