export enum Operator {
    Equal = 'equal',
    Less = 'less',
    Less_or_Equal = 'less_or_equal',
    Greater = 'greater',
    Greater_or_Equal = 'greater_or_equal',
    Not_Equal = 'not_equal',
    Between = 'between',
    Not_Between = 'not_between',
    In = 'in',
    Not_In = 'not_in',
    Is_Null = 'is_null',
    Is_Not_Null = 'is_not_null',
    Contains = 'contains', // for strings
    Begins_With = 'begins_with', // for strings
    Ends_With = 'ends_with', // for strings
}
