import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ubi-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnInit {

  /**
   * Title
   */
  @Input()
  title: string;

  /**
   * Constructor
   * @param route Activated Route
   * @param translate Translate Service
   */
  constructor(private route: ActivatedRoute, private translate: TranslateService) { }

  ngOnInit() {

    const titleFromRoute = this.route.snapshot.data['title'];

    // If both exists them @Input overrides Route
    if (titleFromRoute !== null && titleFromRoute !== undefined
      && (this.title === null || this.title === undefined)) {
      this.title = titleFromRoute;

      this.translate.get(this.title).subscribe((res: any) => {
        this.title = res;
      });

    }
  }
}
