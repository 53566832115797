import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

/**
 * Advanced Search Popup
 * Query Builder with AND / OR and multiple field select
 */
@Component({
  selector: 'ubi-popup-advanced-search',
  templateUrl: './popup-advanced-search.component.html',
  styleUrls: ['./popup-advanced-search.component.scss'],
})
export class PopupAdvancedSearchComponent {

  @Input() query: any;

  @Input() config: any;
  @Input() allowRuleset: any;

  customOperatorMap: {[key: string]: string[]} = {
    string: ['equal', 'contains'],
    number: ['equal', '!=', '>', '>=', '<', '<='],
    time: ['equal', '!=', '>', '>=', '<', '<='],
    date: ['equal', '!=', '>', '>=', '<', '<='],
    category: ['equal', '!=', 'in', 'not in'],
    boolean: ['equal'],
  };

  constructor(protected dialogRef: NbDialogRef<any>) {
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    this.dialogRef.close(this.query);
  }

}
