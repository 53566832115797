import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ActionsService } from './actions.service';
import { BaseService } from './base.service';
import { SettingsService } from './settings.service';
import { FormDefinition } from '../../models/form-definition';

@Injectable({
  providedIn: 'root',
})
export class FormsJsonService extends BaseService {

  constructor(protected httpClient: HttpClient,
    protected logger: NGXLogger,
    protected settings: SettingsService,
    protected actionsService: ActionsService,
  ) {
    super(httpClient, logger, settings, actionsService);
  }

  getForms(): Observable<any> {
    const endpoint = `${this.engineRestUrl}forms`;
    return this.httpClient.get<any>(endpoint)
      .pipe(
        tap(form => this.logger.trace(`fetched forms`)),
        catchError(this.handleError('getForms', [])),
      );
  }

  /**
   * Get forms by Process
   * @param process
   */
  getFormsByProcess(process: string): Observable<any> {
    const endpoint = `${this.engineRestUrl}forms?process=${process}`;
    return this.httpClient.get<any>(endpoint)
      .pipe(
        tap(form => this.logger.trace(`fetched forms`)),
        catchError(this.handleError('getForms', [])),
      );
  }

  /**
   * Get form by id
   * @param id
   */
  getForm(id: string): Observable<any> {
    const endpoint = `${this.engineRestUrl}forms?id=${id}`;
    return this.httpClient.get<any>(endpoint)
      .pipe(
        tap(form => this.logger.trace(`fetched form`)),
        map(form => {
          if (form !== null && form.length > 0) {
            const jsonForm = form[0];
            const formDefinition = new FormDefinition();
            formDefinition.id = jsonForm.id;
            formDefinition.key = jsonForm.formid;
            formDefinition.designation = jsonForm.formdesignation;
            formDefinition.definition = jsonForm.form;
            formDefinition.tags = jsonForm.formtags;

            return formDefinition;
          } else {
            return null;
          }
        }),
        catchError(this.handleError('getForm', [])),
      );
  }

  /**
   * Get form by id
   * @param id
   */
  getFormByKey(key: string): Observable<any> {
    const endpoint = `${this.engineRestUrl}forms?formid=${key}`;
    return this.httpClient.get<any>(endpoint)
      .pipe(
        tap(form => this.logger.trace(`fetched form`)),
        map(form => {
          if (form !== null) {
            return form[0];
          }
        }),
        catchError(this.handleError('getFormBykey', [])),
      );
  }

  /**
   * Save form
   * @param item
   */
  saveForm(item: FormDefinition): Observable<any> {

    if (item.id === item.key) {
      item.id = null;
    }

    const request = {
      form : item.definition,
      formid : item.key,
      formdesignation : item.designation,
      formtags : item.tags,
    };

    if (item.id !== null && item.id !== undefined) {
      const endpoint = `${this.engineRestUrl}forms/${item.id}`;
      return this.httpClient.put<any>(endpoint, request)
        .pipe(
          tap(() => {
            this.logger.trace(`put save form`);
            this.actionsService.savedAction();
          }),
          catchError(this.handleError('saveForm', [])),
        );
    } else {
      const endpoint = `${this.engineRestUrl}forms`;
      return this.httpClient.post<any>(endpoint, request)
        .pipe(
          map(itemSaved => itemSaved),
          tap(() => {
            this.logger.trace(`posted save form`);
            this.actionsService.savedAction();
          }),
          catchError(this.handleError('saveForm', [])),
        );
    }
  }

  /**
   * Delete form
   * @param id
   */
  deleteForm(id: any): Observable<any> {

    if (id !== null && id !== undefined) {
      const endpoint = `${this.engineRestUrl}forms/${id}`;
      return this.httpClient.delete<any>(endpoint)
        .pipe(
          tap(() => {
            this.logger.trace(`put save form`);
            this.actionsService.deletedAction();
            return id;
          }),
          catchError(this.handleError('deleteForm', [])),
        );
    }

  }

}
