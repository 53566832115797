import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

/**
 *
 */
@Component({
  selector: 'ubi-layout-list',
  templateUrl: './list.layout.html',
  styleUrls: ['./list.layout.scss'],
})
export class ListLayoutComponent implements OnInit {

  /**
   * Title
   */
  @Input() title: string;

  /**
   * Options
   */
  @Input() options: any;

  /**
   * Filters
   */
  @Input() filters: any[];

  /**
   * New item event
   */
  @Output() new = new EventEmitter();

  /**
   * Filter event
   */
  @Output() filter = new EventEmitter();

  /**
   * Search event
   */
  @Output() search = new EventEmitter();

  /**
   * Advanced Search event
   */
  @Output() advancedSearch = new EventEmitter();

  /**
   * Search event
   */
  @Output() export = new EventEmitter();

  /**
   * Erase filter event
   */
  @Output() eraseFilter = new EventEmitter();

  constructor(protected router: Router, protected route: ActivatedRoute) { }

  ngOnInit() {
    const defaultOptions = {
      layout: {
        new: true,
        search: false,
      },
    };

    if (this.options === null || this.options === undefined) {
      this.options = Object.assign({}, defaultOptions);
    }
  }

  /**
   *
   * @param event
   */
  newClick(event) {
    this.new.emit();
  }

  /**
   *
   * @param event
   */
  filterClick(event) {
    this.filter.emit(event);
  }

  /**
   *
   * @param event
   */
  advancedSearchClick(event) {
    this.advancedSearch.emit(event);
  }

  /**
   *
   * @param event
   */
  eraseFilterClick(event) {
    this.eraseFilter.emit(event);
  }

  /**
   *
   * @param event
   */
  searchClick(event) {
    this.search.emit(event);
  }

  /**
   *
   * @param event
   */
  exportClick(event) {
    const format = 'Excel';
    this.export.emit(format);
  }

}
