import { Component, OnInit } from '@angular/core';
import { KeycloakEventType } from 'keycloak-angular';
import { NGXLogger } from 'ngx-logger';

import { environment } from '../environments/environment';
import { EventStackService } from './@core/services/event-stack.service';
import { AnalyticsService } from './@core/utils/analytics.service';
import { SettingsService } from './@core/services/settings.service';

const BACKOFFICE_URL = environment.backofficeUrl;
const KEYCLOAK_CONFIG = environment.keycloak;

@Component({
  selector: 'ubi-app',
  template: `<ngx-loading-bar [includeSpinner]="false"></ngx-loading-bar>
             <router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {

  constructor(private analytics: AnalyticsService,
    private eventStackService: EventStackService,
    private logger: NGXLogger, private settings: SettingsService) {
  }

  ngOnInit(): void {
    this.analytics.trackPageViews();

    this.eventStackService.eventTriggered.subscribe(eventStack => {
      eventStack.forEach(eventItem => this.keycloakEventTriggered(eventItem));
    });

    this.eventStackService.eventStack.forEach(eventItem => this.keycloakEventTriggered(eventItem));

    this.settings.backofficeUrl = BACKOFFICE_URL;
    this.settings.keycloakConfig = KEYCLOAK_CONFIG;
  }

  /**
   * Log Event
   * @param event
   * @param msg
   * @param level
   */
  private logEvent(event: string, msg: string, level: 'info' | 'warn' | 'error') {
    switch (level) {
      case 'info':
        this.logger.info(`${event} - ${msg}`);
        break;
      case 'warn':
        this.logger.warn(`${event} - ${msg}`);
        break;
      case 'error':
        this.logger.error(`${event} - ${msg}`);
        break;
      default:
        break;
    }
  }

  /**
   * Keycloak Event Triggered
   * @param param
   */
  private keycloakEventTriggered({ id, event }): void {
    switch (event.type) {
      case KeycloakEventType.OnAuthError:
        this.logEvent('Auth Error', 'Msg', 'error');
        break;
      case KeycloakEventType.OnAuthLogout:
        this.logEvent('Auth Logout', 'Msg', 'warn');
        break;
      case KeycloakEventType.OnAuthRefreshError:
        this.logEvent('Auth Refresh Error', 'Msg', 'error');
        break;
      case KeycloakEventType.OnAuthRefreshSuccess:
        this.logEvent('Auth Refresh Success', 'Msg', 'info');
        break;
      case KeycloakEventType.OnAuthSuccess:
        this.logEvent('Auth Success', 'Msg', 'info');
        break;
      case KeycloakEventType.OnReady:
        this.logEvent('On Ready', 'Msg', 'info');
        break;
      case KeycloakEventType.OnTokenExpired:
        this.logEvent('Token Expired', 'Msg', 'warn');
        break;
      default:
        break;
    }

    this.eventStackService.purgeEventItem(id);
  }

}
