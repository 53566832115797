import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbSidebarService } from '@nebular/theme';
import { NGXLogger } from 'ngx-logger';
import { ActionsService } from '../../../@core/services/actions.service';

@Component({
  selector: 'ubi-detail-header',
  styleUrls: ['./detail-header.component.scss'],
  templateUrl: './detail-header.component.html',
})
export class DetailHeaderComponent implements OnInit {

  /**
   * Title
   */
  @Input() title: string;

  /**
   * Options
   */
  @Input() options: any;

  protected sidebarStatus = this.sidebarService;

  constructor(private sidebarService: NbSidebarService,
    private route: ActivatedRoute,
    private actionsService: ActionsService,
    private logger: NGXLogger) {

  }

  ngOnInit() {
    this.title = this.route.snapshot.data['breadcrumb'];
  }

  submit(event: any) {
    this.logger.trace('Save');
    this.actionsService.submitAction('Save');
  }

}
