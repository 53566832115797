import { Injectable } from '@angular/core';
import { KeycloakConfig } from 'keycloak-angular';

import { Endpoints } from '../model/endpoints';

const CACHE_SIZE = 1;

/**
 * Settings Service
 */
@Injectable()
export class SettingsService {

  /**
   * Get Cache Size
   */
  get cacheSize(): number {
    return CACHE_SIZE;
  }

  /**
   * Workflow Base Url
   */
  workflowUrl: string;

  /**
   * Forms Repository Base Url
   */
  formsRepositoryUrl: string;

  /**
   * Backoffice Base Url
   */
  backofficeUrl: string;

  /**
   * Content Base Url
   */
  contentUrl: string;

  /**
   * Storage Base Url
   */
  storageUrl: string;

  /**
   * API Base Url
   */
  startUrl: Endpoints;

  /**
   * Datatable Options
   */
  datatableOptions: any;

  /**
   * Keycloak condig
   */
  keycloakConfig: KeycloakConfig;

  /**
   * Token Squidex (Temporary)
   */
  tokenSquidex: string;

  formioAppConfig(): any {
    return {
      apiUrl: this.startUrl.base,
    };
  }

  selectedLanguage: string;

  constructor() { }

}
