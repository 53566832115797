import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ActionsService } from './actions.service';
import { BaseService } from './base.service';
import { SettingsService } from './settings.service';

/**
 * Processes Service
 */
@Injectable()
export class ProcessesService extends BaseService {

    constructor(protected httpClient: HttpClient,
        protected logger: NGXLogger,
        protected settings: SettingsService,
        protected actionsService: ActionsService,
      ) {
        super(httpClient, logger, settings, actionsService);
      }

  getDocuments(processId: string) {
    const endpoint = `${this.webApiUrl}/ProcessDocuments/GetDocuments?processId=${processId}`;

    return this.httpClient.get<any[]>(endpoint)
      .pipe(
        tap(form => this.logger.trace(`fetched Documents`)),
        catchError(this.handleError('getDocuments', [])),
      );
  }

  downloadDocument(url: string): Observable<any> {
    return this.httpClient.get(url, { responseType: 'blob' });
  }

  getProcessDefinitions() {
    const endpoint = `${this.webApiUrl}/ProcessDefinitions`;

    return this.httpClient.get<any[]>(endpoint)
      .pipe(
        tap(form => this.logger.trace(`fetched ProcessDefinitions`)),
        catchError(this.handleError('getProcessDefinitions', [])),
      );
  }

  getManualStartProcessDefinitions() {
    const endpoint = `${this.webApiUrl}/ProcessDefinitions/GetManualStartProcesses`;

    return this.httpClient.get<any[]>(endpoint)
      .pipe(
        tap(form => this.logger.trace(`fetched ManualStartProcesses`)),
        catchError(this.handleError('getManualStartProcessDefinitions', [])),
      );
  }
}
