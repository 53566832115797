import { APP_BASE_HREF } from '@angular/common';
import { HttpClient, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbDatepickerModule, NbDialogModule, NbGlobalPhysicalPosition,
  NbMenuModule, NbSidebarModule, NbToastrModule, NbWindowModule,
} from '@nebular/theme';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormioAppConfig } from 'angular-formio';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { CoreModule } from './@core/core.module';
import { SettingsService } from './@core/services';
import { SettingsServiceProvider } from './@core/services/env.service.provider';
import { EventStackService } from './@core/services/event-stack.service';
import { ThemeModule } from './@theme/theme.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InitializerKeycloak, InitializeSettings } from './app.init';
import { AppAuthGuard } from './app.authguard';
import { CustomerAuthGuard } from './customer.authguard';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
    AppRoutingModule,
    NbEvaIconsModule,
    ThemeModule.forRoot(),
    LoggerModule.forRoot(
      {
        level: NgxLoggerLevel.DEBUG,
      }),

    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot({
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
    }),

    CoreModule.forRoot(),
    KeycloakAngularModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,

    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
      },
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    AppAuthGuard,
    CustomerAuthGuard,
    SettingsServiceProvider,
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: FormioAppConfig,
      deps: [SettingsService],
      useFactory: InitializeSettings,
    },
    [
      {
        provide: APP_INITIALIZER,
        useFactory: InitializerKeycloak,
        multi: true,
        deps: [KeycloakService, EventStackService, SettingsService],
      },
    ],
  ],
})
export class AppModule {
}
