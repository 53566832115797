import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

/**
 *
 */
@Component({
  selector: 'ubi-layout-tabs-detail',
  templateUrl: './tabs-detail.layout.html',
  styleUrls: ['./tabs-detail.layout.scss'],
})
export class TabsDetailLayoutComponent implements OnInit {

  /**
   * Title
   */
  @Input()
  title: string;

  /**
   * Options
   */
  @Input() options: any;

  /**
   * onTabChange event
   */
  @Output()
  onTabChange = new EventEmitter();

  constructor(protected router: Router, protected route: ActivatedRoute) {}

  ngOnInit() {
    const defaultOptions = {
      new: true,
    };

    this.options = Object.assign(defaultOptions, this.options);
  }

  tabChange(event) {
    this.onTabChange.emit(event);
  }
}
