
import { Component } from '@angular/core';
import { NbThemeService, NbSidebarService } from '@nebular/theme';
import { StateService } from '../../../@core/data/state.service';
import { ActionsService } from '../../../@core/services/actions.service';
import { SettingsService } from '../../../@core/services';

@Component({
  selector: 'ubi-theme-settings',
  styleUrls: ['./theme-settings.component.scss'],
  template: `
    <h6></h6>
    <div class="settings-row">
      <button nbButton size="xsmall" shape="rectangle" status="primary" (click)="changeLanguage('en')">{{ 'English' | translate }}</button>
      <button nbButton size="xsmall" shape="rectangle" status="primary" (click)="changeLanguage('it')">{{ 'Italian' | translate }}</button>
      <button nbButton size="xsmall" shape="rectangle" status="primary" (click)="changeLanguage('es')">{{ 'Spanish' | translate }}</button>
      <button nbButton size="xsmall" shape="rectangle" status="primary" (click)="changeLanguage('pt')">{{ 'Portuguese' | translate }}</button>
    </div>
  `,
})
export class ThemeSettingsComponent {

  layouts = [];
  sidebars = [];

  constructor(protected stateService: StateService,
    private themeService: NbThemeService,
    private settingsService: SettingsService,
    private actionsService: ActionsService) {
    this.stateService.getLayoutStates()
      .subscribe((layouts: any[]) => this.layouts = layouts);

    this.stateService.getSidebarStates()
      .subscribe((sidebars: any[]) => this.sidebars = sidebars);
  }

  layoutSelect(layout: any): boolean {
    this.layouts = this.layouts.map((l: any) => {
      l.selected = false;
      return l;
    });

    layout.selected = true;
    this.stateService.setLayoutState(layout);
    return false;
  }

  sidebarSelect(sidebars: any): boolean {
    this.sidebars = this.sidebars.map((s: any) => {
      s.selected = false;
      return s;
    });

    sidebars.selected = true;
    this.stateService.setSidebarState(sidebars);
    return false;
  }

  enable(theme: string) {
    this.themeService.changeTheme(theme);
  }

  changeLanguage(language: string) {
    this.settingsService.selectedLanguage = language;
    this.actionsService.languageChangeAction(language);
  }
}
