import { IGenericSerializer } from './igeneric-serializer';

export class GenericSerializer<T extends any> implements IGenericSerializer {
    jsonToObject(json: any): T {
        return json;
    }

    jsonFromObject(object: T): any {
        return object;
    }
}
